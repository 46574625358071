import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Form from "./Form"
import Label from "../Label"
import TextField from "../TextField"
import Group from "./Group"
import TextArea from "../TextArea"
import H2 from "../H2"
import P from "../P"
import Button from "../Button"
import Success from "./Sucess"
import { Grid } from "@material-ui/core"
import theme from "../../themes"
import { useEffect } from "react"

const BookingForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [formSubmitted, setformSubmitted] = useState(false)
  const [kidsState, setKidsState] = useState(Array(2).fill(null))

  const swissPhoneRegex = /^(0|0041|\+41)?[1-9\s][0-9\s]{1,12}$/
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {}, kidsState)

  const onSubmit = data => {
    console.log("before post")
    console.log(data)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(data),
    })
      .then(() => {
        setformSubmitted(true)
      })
      .catch(error => alert(error))
  }

  async function addKid() {
    const kidList = [...kidsState]

    if (kidsState.length > 4) return

    kidList.push({ name: "", birthday: "" })
    setKidsState(kidList)
  }

  async function removeKid() {
    if (kidsState.length === 1) return

    console.log("kidsStateLenght = " + kidsState.length)

    const kidList = [...kidsState]
    const deleted = kidList.splice(0, kidList.length - 1)
    console.log("new kidList")
    console.log(kidList)
    console.log("new deleted")
    console.log(deleted)

    await setKidsState(deleted)
  }

  if (formSubmitted)
    return (
      <Success>
        <H2>Vielen Dank!</H2>
        <P>
          Ihre Anfrage wurde erfolgreich übermittelt. Wir melden uns in kürze
          bei Ihnen!
        </P>
      </Success>
    )

  return (
    <Form
      name="buchen_kita"
      action="/"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <TextField
        type="hidden"
        name="form-name"
        value="buchen_kita"
        ref={register}
      />
      <TextField type="hidden" name="bot-field" />
      <TextField
        type="hidden"
        name="subject"
        value="Neue Anfrage"
        ref={register}
      />

      <Group>
        <Label htmlFor="fullname">Ihr Name</Label>
        <TextField
          id="fullname"
          name="fullname"
          placeholder="Max Muster"
          ref={register({
            required: true,
          })}
          error={errors.fullname ? true : undefined}
        ></TextField>
        {errors.fullname && <p>Bitte geben Sie einen Namen an</p>}
      </Group>
      <Group>
        <Label htmlFor="replyTo">Ihre E-Mail</Label>
        <TextField
          id="replyTo"
          name="replyTo"
          placeholder="max@muster.ch"
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Ungültige E-Mail!",
            },
          })}
          error={errors.replyTo ? true : undefined}
        ></TextField>
        {errors.replyTo && <p>Bitte geben Sie eine gültige E-Mail an</p>}
      </Group>
      <Group>
        <Label htmlFor="phone">Ihre Telefonnummer</Label>
        <TextField
          id="phone"
          name="phone"
          placeholder="+41 xx.. oder 07x xxx xx xx"
          ref={register({
            required: true,
            pattern: {
              value: swissPhoneRegex,
              message: "Ungültige Telefonnummer!",
            },
          })}
          error={errors.phone ? true : undefined}
        ></TextField>
        {errors.phone && <p>Bitte geben Sie eine gültige Telefonnummer an</p>}
      </Group>

      {kidsState.map((val, idx) => {
        // start counting by 1
        idx++
        const kidId = `kid_${idx}`
        const birthdayid = `birthday_${idx}`
        return (
          <Grid container spacing={4} key={idx}>
            <Grid item xs={12} sm={6} style={{ marginBottom: "16px" }}>
              <Group>
                <Label htmlFor={kidId}>Name des Kindes {idx}</Label>
                <TextField
                  id={kidId}
                  name={kidId}
                  data-idx={idx}
                  ref={register({
                    required: "Name des Kindes angeben",
                  })}
                />
                {errors[kidId] && <p>{errors[kidId].message}</p>}
              </Group>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: "16px" }}>
              <Group>
                <Label htmlFor={birthdayid}>Geburtstag des Kindes {idx}</Label>
                <TextField
                  id={birthdayid}
                  name={birthdayid}
                  data-idx={idx}
                  ref={register({
                    required: "Geburtsdatum des Kindes angeben",
                  })}
                />
                {errors[birthdayid] && <p>{errors[birthdayid].message}</p>}
              </Group>
            </Grid>
          </Grid>
        )
      })}

      <Group style={{ display: "none", justifyContent: "space-between" }}>
        <Button
          onClick={() => addKid()}
          type="button"
          background={theme.main.colors.green}
        >
          + Weiteres Kind hinzufügen
        </Button>
        <Button onClick={() => removeKid()} type="button">
          - Letztes Kind entfernen
        </Button>
      </Group>

      <Group>
        <Label htmlFor="message">Ihre Nachricht</Label>
        <TextArea
          id="message"
          name="message"
          placeholder="Geben Sie hier Ihr Anliegen ein. Wir melden uns in Kürze bei Ihnen."
          rows="6"
          ref={register({
            required: true,
          })}
          error={errors.message ? true : undefined}
        ></TextArea>
        {errors.message && <p>Bitte geben Sie Ihre Mitteilung ein</p>}
      </Group>

      <Group>
        <Button>Nachricht absenden</Button>
      </Group>
    </Form>
  )
}

export default BookingForm
