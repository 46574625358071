import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"
import { Grid } from "@material-ui/core"
import theme from "../themes"
import BookingForm from "../components/Booking-Form"

const BuchenPage = () => {
  return (
    <Layout>
      <SEO title="Buchen" />
      <Section paddingTop={theme.main.sizes.paddings.bigger}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={8} style={{ marginBottom: "80px" }}>
              <H1>Buchen</H1>
              <p>Buchen Sie einen freien Platz in der Kita.</p>
              <BookingForm />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default BuchenPage
